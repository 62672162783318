// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$front-end-primary: mat.define-palette(mat.$indigo-palette);
$front-end-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$front-end-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$front-end-theme: mat.define-light-theme(
  (
    color: (
      primary: $front-end-primary,
      accent: $front-end-accent,
      warn: $front-end-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($front-end-theme);

/* You can add global styles to this file, and also import other style files
 @import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
*/

@import "material-icons/iconfont/material-icons.css";
@import "./assets/plugins/global/plugins.bundle.css";
@import "./assets/css/style.bundle.css";

// angular material dialog and sidebar fix
html {
  top: 0 !important;
}

$fa-font-path  : '../../fonts';
$roboto-font-path : '../../../fonts';

@import "@bryntum/core-thin/sass/themes/material.scss";
@import "@bryntum/grid-thin/sass/themes/material.scss";
@import "@bryntum/scheduler-thin/sass/themes/material.scss";
@import "@bryntum/schedulerpro-thin/sass/themes/material.scss";
@import "@bryntum/calendar-thin/sass/themes/material.scss";


.column-padding {
  margin-right: 5px;
}
.cell-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-nonwork-hour {
  background-color: rgb(237, 237, 215) !important;
}

.inline-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.column-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 5px;
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.my-bounce {
  /*
  animation: bounce;
  animation-duration: 2s;
  */
}

.back-table {
  background-color: #eaecf4;
}

.green {
  color: green;
}

.red {
  color: red;
}

.yellow {
  color: orange;
}

.blue {
  color: cadetblue;
}

.centered {
  text-align: center;
}

.ic {
  height: 33px;
  cursor: pointer;
  color: #e5e8f5;
}

.inline-icon {
  vertical-align: bottom;
  font-size: 40px;
}

.icsave {
  height: 33px;
  cursor: pointer;
  color: #293683;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttons-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.group-button-map {
  display: flex;
}

.wbck {
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
}

.with-icon {
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 5px;
}

.aligned-with-icon {
  position: absolute;
  margin-top: 5px;
  margin-left: 5px; /* optional */
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wbckmaps {
  background-color: whitesmoke;
  border-radius: 5px;
  margin: 5px;
}

.section-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.icw {
  color: #293683;
  margin-left: 5px;
  vertical-align: -5px;
}

.icwsubmenu {
  color: #293683;
  height: 8px;
  margin-left: 5px;
}

.my-container {
  padding-bottom:100px!important;
  background-color: #f3f4f9;
  padding: 20px;
  border-radius: 20px;
  transform: perspective(900px) rotateX(0deg) scale(1);
  box-shadow: 0px 20px 100px #555;
  transition: 0.5s ease all;
  /*
        &:hover {
            transform:
                rotate(0deg)
                scale(1)
                translateY(10px);
    }
*/
}

.search-wrapper {
  margin: 24px;
  border-radius: 24px;
  max-width: 100%;
  overflow: hidden;
  background: white;

  .search {
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .material-icons {
      transition: transform 0.5s;

      &.animate {
        -moz-animation: rotating 2s linear infinite;
        -ms-animation: rotating 2s linear infinite;
        -o-animation: rotating 2s linear infinite;
        animation: rotating 2s linear infinite;
      }
    }

    input {
      height: 48px;
      min-height: 48px;
      max-height: 48px;
      padding: 0 16px;
      border: none;
      outline: none;
      font-size: 1.5em;
      color: gray;
      flex: 1 1 auto;
    }
  }
}

.custom-dialog-container .mat-dialog-content {
  overflow: visible !important;
}
.custom-dialog-container .mat-dialog-container {
  overflow: visible !important;
}
.custom-dialog-container .mat-dialog-actions {
  clear: both;
}
.noBorderMyContainer .my-container {
  border-radius: 0px !important;
}
.boldText {
  font-weight: bold;
}

.special-date2 {
  border: 10px;
  background-color: #fbe74d !important;
  border-radius: 50%;
  color: yellow !important;
}
